import {
    Box,
    Flex,
    SegmentedControl,
    Title,
    useMantineTheme,
} from "@mantine/core";
import { useLocation } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import MetadataDocuments from "src/components/MetadataDocuments";
import { FSR, SF } from "src/db";

const titles: Record<typeof SF | typeof FSR, string> = {
    fsr: "Field Service Reports",
    sf: "Salesforce Cases",
};

export const DocumentsRoute = ({
    index,
}: { index?: typeof SF | typeof FSR }) => {
    const [selectedIndex, setSelectedIndex] = useState(index ?? FSR);
    const theme = useMantineTheme();
    const location = useLocation();
    useEffect(() => {
        if (location.pathname === "/salesforce" && selectedIndex !== SF) {
            setSelectedIndex(SF);
        } else if (
            location.pathname === "/documents" &&
            selectedIndex !== FSR
        ) {
            setSelectedIndex(FSR);
        }
    }, [location.pathname]);
    return (
        <Flex p="xl" direction="column" gap="md">
            <Flex justify="space-between">
                <Title lh={2} c="purple.8" order={2} transform="uppercase">
                    {titles[selectedIndex]}
                </Title>
                <Box mt="xs">
                    <SegmentedControl
                        color={theme.colors.purple[7]}
                        styles={{
                            label: {
                                fontWeight: "bold",
                            },
                        }}
                        fullWidth
                        value={selectedIndex}
                        onChange={async (value: typeof SF | typeof FSR) => {
                            setSelectedIndex(value);
                            // navigate({ to: "/" })
                        }}
                        data={[SF, FSR].map((key) => ({
                            label: key === SF ? "Salesforce Cases" : "Field Service Reports",
                            value: key,
                        }))}
                    />
                </Box>
            </Flex>
            <Box>
                <MetadataDocuments index={selectedIndex} />
            </Box>
        </Flex>
    );
};
