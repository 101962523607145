import {
    Container, SegmentedControl, Space, useMantineTheme
} from "@mantine/core";
import { useLiveQuery } from "dexie-react-hooks";
import { useEffect, useMemo } from "react";
import {
    type ChatVectorIndexShorthand,
    FSR, SF,
    chatVectorIndex,
    db
} from "../db";
import { useChatId } from "../hooks/useChatId";

import { useNavigate } from "@tanstack/react-router";
import { useFeature } from "flagged";
import { useTab } from "../contexts/tabContext";
import { ChatRouteChild } from "./ChatRoute";

const salesForceSuggestions = [
    "Shaft Speed Spread probe sensors are found to be out of expected range, can you provide the symptom classes  and step wise recommendations?",
    "What the most common problems associated to TCP rotors?",
    "List down details of problems and corresponding solution/recommendations for subject/problems related to IGV units?",
    "What are the causes of high delta differential pressure in the primary filter stage of an air intake system of an SGT-800?",
];

export const fieldServiceSuggestions = [
    "Provide the conclusion and recommendations to the customer MODEC Carioca for the commissiong activity on SGT-A35.",
    "What was the reason for shutdown in HI6074/03?",
    "List the reasons for outage issues for customer Gail having machine no. R375/02 from year 2019 to 2023.",
    "When was the last gas turbine changeout at North Rankin?",
];

export const mindMapSuggestions = [
    "What should I check if I have BOV drift ?",
    "What should I check if the BOV demand differs from feedback ?",
    "What are the latest standard bolts to be used on BOV ?",
    "SGT100 Rexroth starter system setup",
];

const CHUNK_SIZE = 50;

const useSelectedQuestions = (selectedTabShorthand: string) =>
    useMemo(() => {
        if (selectedTabShorthand === FSR) return fieldServiceSuggestions;
        if (selectedTabShorthand === SF) return salesForceSuggestions;
        if (selectedTabShorthand === "mindmaps") return mindMapSuggestions;
        return [...salesForceSuggestions.slice(0, 2), ...fieldServiceSuggestions.slice(0, 2)];
    }, [selectedTabShorthand]);

export function ChatRoute() {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const chatId = useChatId();

    const chats = useLiveQuery(() =>
        db.chats.orderBy("createdAt").reverse().toArray(),
    );
    const { selectedTabShorthand, selectedTab, setSelectedTab } = useTab();
    const selectedQuestions = useSelectedQuestions(
        selectedTabShorthand,
        selectedTab,
    );

    const messages = useLiveQuery(() => {
        if (!chatId) return [];
        return db.messages.where("chatId").equals(chatId).sortBy("createdAt");
    }, [chatId]);

    useEffect(() => {
        const foundChat = chats
            ?.filter((chat) => chat.id.startsWith(selectedTabShorthand))
            ?.find((chat) => chat.id === chatId);

        if (foundChat?.id && foundChat?.id !== chatId) {
            navigate({ to: `/chats/${foundChat.id}` });
        }
    }, [selectedTabShorthand, chatId]);

    const isMindmaps = useFeature("MINDMAPS");

    return (
        <>
            <Container size="sm" mt="xl">
                <SegmentedControl
                    color={theme.colors.purple[7]}
                    styles={{
                        label: {
                            fontWeight: "bold",
                        },
                    }}
                    fullWidth
                    value={selectedTab}
                    onChange={async (value: ChatVectorIndexShorthand) => {
                        setSelectedTab(value);
                        navigate({ to: "/" });
                    }}
                    data={chatVectorIndex
                        .filter((index) =>
                            isMindmaps ? true : index.shorthand !== "mindmaps",
                        )
                        .map((vector) => ({ value: vector.value[0], label: vector.label }))}
                />
            </Container>
            <Space h="lg" />
            <ChatRouteChild
                selectedQuestions={selectedQuestions}
                chatId={chatId}
                messages={messages}
            />
        </>
    );
}
