import React from "react";
import { createFileRoute } from '@tanstack/react-router'
import { DocumentsRoute } from '../../containers/DocumentsRoute'

export const Route = createFileRoute('/_layout-nosidebar/documents')({
  component: Documents,
})

function Documents() {
  return <DocumentsRoute index="fsr" />
}
