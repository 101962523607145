import { Autocomplete, Box, Button, Card, Flex, Loader, rem, SimpleGrid, Stack, Text } from "@mantine/core";
import { useLocation } from "@tanstack/react-router";
import { IndexableType } from "dexie";
import { useEffect, useRef, useState } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { RichTextareaHandle } from "rich-textarea";
import { MessageItem, sendFeedbackToApi } from "src/components/MessageItem";
import { MessageEntity } from "src/db";
import { useChatCompletion } from "src/hooks/useChatCompletion";
import { useMetadata } from "src/hooks/useMetadata";

export function ChatRouteChild({
    selectedQuestions,
    chatId,
    messages,
    files,
    isCompact,
}: {
    readonly selectedQuestions: string[];
    readonly chatId?: IndexableType;
    readonly messages?: MessageEntity[];
    readonly files?: string[];
    readonly isCompact?: boolean;
}) {
    // if url has search param isNew then we show loading, make it using tanstack router so it's updated when the url changes
    const location = useLocation();
    const isLoading = location.searchStr.includes("isNew");
    const { chatMetadata, addChatMetadata } = useMetadata();

    useEffect(() => {
        if (files && files?.length > 0) {
            files.forEach((file) => {
                if (
                    chatMetadata.find(
                        (metadata) =>
                            metadata.key === "report_name" && file.startsWith(metadata.value),
                    )
                )
                    return;
                addChatMetadata(file.split("_")[0], "file");
            });
        }
    }, [files]);
    // const chatGPT = useChatGPT()

    const chatCompletion = useChatCompletion();

    const [content, setContent] = useState("");

    const ref = useRef<RichTextareaHandle>(null);

    const submit = async () => {
        const newContent = content;
        setContent("");
        await chatCompletion.sendMessage(newContent, false, chatId);
    };

    return (
        <>
            <Stack spacing="xs" px="xl" pb="12rem">
                {messages?.map((message, i) => (
                    // Send next message if it exists
                    <MessageItem
                        sendFeedbackToApi={sendFeedbackToApi}
                        retry={(newMessage: string) => {
                            setContent("");;
                            return chatCompletion.sendMessage(newMessage, true, chatId)
                        }}
                        key={message.id.toString()}
                        message={message}
                        nextAssistantMessage={
                            messages[i + 1] && i > 1 ? messages[i + 1] : undefined
                        }
                    />
                ))}
            </Stack>
            <Box
                py="lg"
                px="xl"
                sx={(theme) =>
                    isCompact
                        ? {}
                        : {
                            position: "fixed",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            [`@media (min-width: ${theme.breakpoints.md})`]: {
                                left: rem(200),
                            },
                            [`@media (min-width: ${theme.breakpoints.lg})`]: {
                                left: rem(300),
                            },
                            backgroundColor:
                                theme.colorScheme === "dark"
                                    ? theme.colors.dark[9]
                                    : theme.colors.gray[0],
                        }
                }
            >
                <Stack spacing="0">
                    <SimpleGrid
                        cols={2}
                        spacing="sm"
                        style={{ maxWidth: "100%" }}
                        mb={messages && messages?.length === 0 ? "sm" : "0"}
                    >
                        {!messages?.length
                            ? selectedQuestions.map((question) => (
                                <Card
                                    shadow="sm"
                                    padding="lg"
                                    radius="md"
                                    withBorder
                                    key={question}
                                    variant="outline"
                                    style={{
                                        cursor: "pointer",
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-word",
                                        textAlign: "left",
                                    }}
                                    onClick={() => setContent(question)}
                                >
                                    {question}
                                </Card>
                            ))
                            : null}
                    </SimpleGrid>

                    <Flex gap="sm" style={{ position: "relative" }}>
                        <Autocomplete
                            ref={ref}
                            value={content}
                            style={{ width: "100%" }}
                            placeholder="Your message here..."
                            maxDropdownHeight={200}
                            data={messages?.length ? selectedQuestions : []}
                            onChange={(value) => setContent(value)}
                        />
                        <Button
                            data-testid="send-button"
                            h="auto"
                            left="0.25rem"
                            variant="filled"
                            onClick={submit}
                            disabled={chatCompletion.isloading || isLoading || !content}
                        >
                            {chatCompletion.isloading || isLoading ? (
                                <Loader color="rgba(82, 82, 82, 1)" size="sm" />
                            ) : (
                                <AiOutlineSend />
                            )}
                        </Button>
                    </Flex>
                    <Text
                        fw="bold"
                        c="gray.7"
                        mt=".5rem"
                        fz="xs"
                        align="center"
                        sx={{ maxWidth: "80%", margin: "0 auto" }}
                    >
                        CTS Knowledge Search uses Generative AI and is still experimental.
                        Please verify the answers for accuracy before using them.
                    </Text>
                </Stack>
            </Box>
        </>
    );
}